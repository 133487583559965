@import "icomoon.css";

.logoText {
    font-size: 38px !important;
    text-align: center !important;
    color: white;
}

.error-enter {
    opacity: 0;
    transform: scale(0.9);
}

.error-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.error-exit {
    opacity: 1;
}

.error-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.tableLoadingTitle {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}

.tableLoadingTitle.showTitle {
    visibility: visible;
    opacity: 1;
}

.showDropDown {
    overflow: visible !important;
}

.html {
    overflow-y: hidden;

}

.sparkleTimePicker .rdtCounters .rdtCounter .rdtCount {
    color: #e14eca !important;
    border-radius: 50% !important;
    border: 1px solid #e14eca !important;
}


.DayPicker {
    background: inherit;
}

.sparkleDayPicker .DayPicker-wrapper {
    outline: none !important;
}

.sparkleDayPicker .DayPicker-Day {
    background-color: inherit;
}

.sparkleDayPicker .DayPicker-Day--selected {
    background-color: #e14eca !important;
}

.sparkleDayPicker.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: transparent !important;
}

.ordineCreato {
    color: #cfd8dc;
    text-transform: uppercase;
    font-weight: 500;
    background-color: rgba(207, 216, 220, 0.3);
    padding: 2px;
    border-radius: 4px;
    text-align: center;
}

.ordineApprovato {
    color: #2dce89;
    text-transform: uppercase;
    font-weight: 500;
    background-color: rgba(45, 206, 137, 0.3);
    padding: 2px;
    border-radius: 4px;
    text-align: center;
}

.ordineFirmato {
    color: #9179F2;
    text-transform: uppercase;
    font-weight: 500;
    background-color: rgba(145, 121, 242, 0.3);
    padding: 2px;
    border-radius: 4px;
    text-align: center;
}

.ordineRifiutato {
    color: #fd5d93;
    text-transform: uppercase;
    font-weight: 600;
    background-color: rgba(253, 93, 147, 0.3);
    padding: 2px;
    border-radius: 4px;
    text-align: center;
}

.ReactTable .rt-expander:after {
    border-top: 7px solid #f4f5f7 !important;
}

.assegnazioni .card:hover {
    zoom: 1.1;
}

.assigned {
    border: 2px solid #2dce89 !important;
}

.notAssigned {
    border: 2px solid #f5365c !important;
}

.extra-large-sparkle-modal .modal-dialog {
    max-width: 90% !important;
    padding: 20px;
}

.input-group-text {
    margin-left: 10px !important;
}

.assegnazioneCompleta {
    color: #00f2c3;
    text-transform: uppercase;
    font-weight: 600;
    background-color: rgba(0, 242, 195, 0.3);
    padding: 2px;
    border-radius: 4px;
    text-align: center;
}

.assegnazioneParziale {
    color: #fb6340;
    text-transform: uppercase;
    font-weight: 600;
    background-color: rgba(251, 99, 64, 0.3);
    padding: 2px;
    border-radius: 4px;
    text-align: center;
}

.assegnazioneAssente {
    color: #f5365c;
    text-transform: uppercase;
    font-weight: 400;
    background-color: rgba(245, 54, 92, 0.3);
    padding: 2px;
    border-radius: 4px;
    text-align: center;
}

.analyticsCentrata {
    text-transform: uppercase;
    font-weight: 400;
    padding: 2px;
    border-radius: 4px;
    text-align: center;
}

.DateInput_input {
    color: white !important;
    background-image: none !important;
    background: none !important;
    background-color: transparent !important;
    border-color: #2b3553;
    border-radius: 0;
    /*border-radius: 0.4285rem;*/
}

.CalendarDay__selected_span {
    background: rgba(137, 101, 224, 0.3);
    color: white;
    border: rgba(137, 101, 224, 1);
}

.CalendarDay__selected {
    background: #8965e0;
    color: white;
    border: rgba(137, 101, 224, 1);

}

.CalendarDay__selected:hover {
    background: #5e72e4;
    color: white;
    border: rgba(137, 101, 224, 1);
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #8965e0;
    color: white;
    border: rgba(137, 101, 224, 1);
}

/*TABLE*/
.rthfc .rt-th,
.rthfc .rt-td {
    background-color: transparent !important;
}

.rthfc .-headerGroups .rt-th {
    background-color: transparent !important;
}

.rthfc.-striped .rt-tr.-odd .rt-td {
    background-color: transparent !important;
}

.rthfc.-highlight .rt-tr:hover .rt-td {
    background-color: transparent !important;
}

.rthfc .-filters .rt-th.rthfc-th-fixed-left-last,
.rthfc .rt-th.rthfc-th-fixed-left-last,
.rthfc .rt-td.rthfc-td-fixed-left-last {
    border-right: dashed 0px rgba(86, 3, 173, 0.3);

}

.rthfc .rt-th.rthfc-th-fixed-right-first,
.rthfc .rt-td.rthfc-td-fixed-right-first {
    border-left: solid 0px rgba(0, 0, 0, 0.30);
}

.rthfc.-sp .rthfc-th-fixed,
.rthfc.-sp .rthfc-td-fixed {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    background-color: black !important;
}

.rthfc.-se .-header .rt-th.rthfc-th-fixed,
.rthfc.-se .-headerGroups .rt-th.rthfc-th-fixed,
.rthfc.-se .-filters .rt-th.rthfc-th-fixed,
.rthfc.-se .rt-td.rthfc-td-fixed {
    position: relative;
    z-Index: 1;
    background-color: #27293d !important;
}

.importoFatturato {
    color: #00f2c3;
    text-transform: uppercase;
    font-weight: 600;
    background-color: rgba(0, 242, 195, 0.3);
    padding: 2px;
    font-size: 12px;
    border-radius: 4px;
    text-align: center;
}

.floatTotale {
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    background-color: rgba(137, 101, 224, 0.3);
    padding: 2px;
    font-size: 12px;
    border-radius: 4px;
    text-align: center;
}

.floatTotale {
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    background-color: rgba(137, 101, 224, 0.3);
    padding: 2px;
    font-size: 12px;
    border-radius: 4px;
    text-align: center;
}

.analyticsAnnullateVuote {
    color: #cfd8dc;
    text-transform: uppercase;
    font-weight: 500;
    background-color: rgba(207, 216, 220, 0.3);
    padding: 2px;
    border-radius: 4px;
    text-align: center;
}

.analyticsAnnullatePiene {
    color: rgba(29, 140, 248, 1);
    text-transform: uppercase;
    font-weight: 500;
    background-color: rgba(29, 140, 248, 0.3);
    padding: 2px;
    border-radius: 4px;
    text-align: center;
}

.firmaSms {
    height: 100%;
    width: 100%;
    color: white;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    display: flex;
}

.cardOrder {
}

.assignmentContent {
    display: flex;
    flex-direction: column;
}

.capitalize {
    text-transform: capitalize;
}

.timeline-badge {
    background: #8965e0 !important;
}

.timeline-panel {
    width: 88% !important;
}


.timeline:before {
    left: 5% !important;
    background-color: #525f7f !important;
}


.timeline-heading-left {
    color: white;
    position: absolute;
    top: 0px;
    right: 0px;
    text-align: right;
    padding: 20px;
    color: #adb5bd;
}

.badge badge-danger badge-pill {
    line-height: 14px !important;
    font-size: 14px !important;
}

.timeline-badge {
    left: 5% !important;
}

.timeline-body > p {
    color: white !important;
}


@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .react-select {
        font-size: 16px !important;
    }

    input {
        font-size: 16px !important;
    }

    .react-select__placeholder, .react-select__input {
        font-size: 16px !important;
    }
}

/* REACT CALENDAR */
.react-calendar-timeline * {
    color: white;
    box-sizing: border-box;
}

.react-calendar-timeline .rct-outer {
    display: block;
    overflow: hidden;
    white-space: nowrap;
}

.react-calendar-timeline .rct-scroll {
    display: inline-block;
    white-space: normal;
    vertical-align: top;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-touch-action: none;
    touch-action: none;
}

.react-calendar-timeline .rct-item:hover {
    z-index: 88;
}

.react-calendar-timeline .rct-item .rct-item-content {
    position: sticky;
    position: -webkit-sticky;
    left: 0px;
    overflow: hidden;
    display: inline-block;
    border-radius: 2px;
    padding: 0 6px;
    height: 100%;
}

.react-calendar-timeline .rct-sidebar {
    overflow: hidden;
    white-space: normal;
    display: inline-block;
    vertical-align: top;
    position: relative;
    box-sizing: border-box;
    border: none !important;;
    border-right: 0 solid #bbb;
}

.react-calendar-timeline .rct-sidebar.rct-sidebar-right {
    border-right: 0;
    border-left: 0 solid #bbb;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    padding: 0 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: content-box;
    margin: 0;
    border: none;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
    background: rgba(0, 0, 0, 0.05);
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
    background: transparent;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
    position: absolute;
    border-left: 0px solid #bbb;
    z-index: 30;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
    border-left-width: 0px;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background: rgba(250, 246, 225, 0.5);
}

.react-calendar-timeline .rct-horizontal-lines {
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    border-bottom: 0px solid #bbb;
    box-sizing: content-box;
    z-index: 40;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    background: rgba(0, 0, 0, 0.05);
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
    background: transparent;
}

.react-calendar-timeline .rct-cursor-line {
    position: absolute;
    width: 2px;
    background: #2196f3;
    z-index: 51;
}

.react-calendar-timeline .rct-dateHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-bottom: 1px solid #bbb;
    cursor: pointer;
    font-size: 14px;
    border-left: none;
}

.react-calendar-timeline .rct-dateHeader:first-child {
    border-bottom: none;
}

.react-calendar-timeline .rct-dateHeader-primary {
    background-color: initial;
    color: #fff;
}

.react-calendar-timeline .rct-header-root {
    border: none;
    background-color: transparent;
}

.react-calendar-timeline .rct-calendar-header {
    border: none;
}

.rct-vl {
    background-color: rgba(137, 101, 224, 0.1);
}

.rct-vl:nth-child(2n+3) {
    background-color: rgba(86, 3, 173, 0.1);
}

.react-calendar-timeline .rct-dateHeader {
    background-color: rgba(137, 101, 224, 0.1);
}

.react-calendar-timeline .rct-dateHeader:nth-child(2n+3) {
    background-color: rgba(86, 3, 173, 0.1);
}

.rct-items > div {
    border-radius: 6px;
    background-color: rgba(255, 141, 114, 0.6) !important;
    border: none !important;
}

.customDropDownAss {
    padding: 5px;
}

.customDropDownAss:hover {
    cursor: pointer;
}

.sweet-alert input {
    display: block !important;
}

.react-tagsinput {
    background-color: transparent !important;
    border: none !important;
}

.react-tagsinput-tag {
    border: none !important;
}

.orderDetailData {
    display: flex;
    color: white;
}

.orderDetailValue {
    margin-left: 10px;
    font-weight: bold;
    /*
    text-transform: uppercase;
    */
}

.detailOrderFooter {
    font-weight: bold;
}

.periodWrapper {
    padding: 10px;
}

.nomeLavoratoreDettaglio {
    color: white;
    font-size: 16px;
    font-weight: 600;
}

.periodsWrapper {
    display: flex;
    flex-direction: row;
    padding: 10px;
    flex-wrap: wrap;
}

.dettaglioFirma {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 600;
    color: white;
    text-align: left;
}

.react-select__input > input {
    color: white !important;
}

.smsSent {
    color: #62D96B;
    text-transform: uppercase;
    font-weight: 600;
    background-color: rgba(98, 217, 107, 0.3);
    padding: 2px;
    border-radius: 4px;
    text-align: center;
}

.smsReaded {
    color: #62D96B;
    text-transform: uppercase;
    font-weight: 600;
    background-color: rgba(98, 217, 107, 0.5);
    padding: 2px;
    border-radius: 4px;
    text-align: center;
}

.smsToSend {
    color: #DB3737;
    text-transform: uppercase;
    font-weight: 600;
    background-color: rgba(219, 55, 55, 0.3);
    padding: 2px;
    font-size: 10px;
    border-radius: 4px;
    text-align: center;
}

.assAccepted {
    color: #62D96B;
    text-transform: uppercase;
    font-weight: 600;
    background-color: rgba(98, 217, 107, 0.5);
    padding: 2px;
    border-radius: 4px;
    text-align: center;
}

.assRefused {
    color: #DB3737;
    text-transform: uppercase;
    font-weight: 600;
    background-color: rgba(219, 55, 55, 0.3);
    padding: 2px;
    border-radius: 4px;
    text-align: center;
    animation: blinker 1s linear infinite;
}

.assRefusedName {
    color: #DB3737;
    text-transform: uppercase;
    font-weight: 600;
    background-color: rgba(219, 55, 55, 0.3);
    padding: 2px;
    border-radius: 4px;
    text-align: center;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.assInfoTooltip {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.assInfo {
    text-align: left;
}


.orderWithAssNotReady {
    color: #DB3737;
    text-transform: uppercase;
    font-weight: 600;
    background-color: rgba(219, 55, 55, 0.3);
    padding: 2px;
    border-radius: 4px;
    text-align: center;
    animation: blinker 1s linear infinite;
}

.assegnazioniAccettate {
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    background-color: rgba(0, 242, 195, 0.5);
    padding: 2px;
    border-radius: 4px;
    text-align: center;
}

.assegnazioniAccettateParzialmente {
    color: yellow;
    text-transform: uppercase;
    font-weight: 600;
    background-color: rgba(98, 217, 107, 0.3);
    padding: 2px;
    border-radius: 4px;
    text-align: center;
    animation: blinker 1s linear infinite;
}

.magazzino > .content {
    padding: 0px 0px 0px 280px !important;
}

/**
 * The geosuggest module
 * NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
 */
.geosuggest {
    font-size: 18px;
    font-size: 1rem;
    position: relative;
    text-align: left;
}

.geosuggest__input {
    width: 100%;
    border: 2px solid transparent;
    box-shadow: 0 0 1px #3d464d;
    padding: .5em 1em;
    -webkit-transition: border 0.2s, box-shadow 0.2s;
    transition: border 0.2s, box-shadow 0.2s;
}

.geosuggest__input:focus {
    border-color: #267dc0;
    box-shadow: 0 0 0 transparent;
}

.geosuggest__suggests {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    margin-top: -1px;
    background: #fff;
    border: 2px solid #267dc0;
    border-top-width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    -webkit-transition: max-height 0.2s, border 0.2s;
    transition: max-height 0.2s, border 0.2s;
}

.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
    font-size: 18px;
    font-size: 1rem;
    padding: .5em .65em;
    cursor: pointer;
    color: black;
}

.geosuggest__item:hover,
.geosuggest__item:focus {
    background: #f5f5f5;
}

.geosuggest__item--active {
    background: #267dc0;
    color: #fff;
}

.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
    background: #ccc;
}

.geosuggest__item__matched-text {
    font-weight: bold;
}

.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}

.geosuggest__item--active {
    background: #267dc0;
    color: #fff;
}

.sidebar[data="oversinerco"],
.off-canvas-sidebar[data="oversinerco"] {
    background: #27293d;
}

.sidebar[data="oversinerco"]:before,
.off-canvas-sidebar[data="oversinerco"]:before {
    border-bottom-color: #27293d;
}


.sidebar[data="savilog"],
.off-canvas-sidebar[data="savilog"] {
    background: #27293d;
}

.sidebar[data="savilog"]:before,
.off-canvas-sidebar[data="savilog"]:before {
    border-bottom-color: #27293d;
}

.sidebar-mini .logo {
    height: 60px;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}

.sidebar-mini .logo-mini {
}

.sidebar-mini .logo-img {
    width: 30px !important;
}

.logo-img > img {
    width: 70px !important;
}

.logo-mini {
    width: 120px !important;
}

.logo {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.sidebar-wrapper:hover .logo {
    height: 100px;
}

.sidebar-wrapper:hover .logo-mini {

}

.sidebar-wrapper:hover .logo-img > img {
    width: 70px !important;
}

.sidebar-wrapper:hover .logo-img {
    width: 120px !important;
}

.sub-menu-icon {
    margin-left: 30px;
}

.sidebar-wrapper:hover .sub-menu-icon {
    margin-left: 5px;
}


.sidebar-mini .sub-menu-icon {
    margin-left: 5px;
}

#peerVideo {
    max-width: 100%;
    max-height: 100%;
}

.welcomeMessageWrapper {
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.welcomeMessage {
    color: white;
}

.welcomeMessageRuolo {
    font-size: 12px;
}

@media only screen and (max-width: 992px) {
    .welcomeMessage {
        color: black;
    }

    .welcomeMessageWrapper {
        margin-top: 7px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

}

.sectorsRolesLabel {
    color: #6e7dd3;
}

.sectorsRolesLabel:hover {
    cursor: pointer;
}

.sectorsRoles {
    color: #6e7dd3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.sectorsRoles {
    color: #6e7dd3;
}


.imgAiuto {
    padding: 20px;
    display: block;
    margin: 0 auto;
}

.pulsante-azioni {
    margin-top: 5px !important;
}

.ordineVariazione {
    color: #FF66A1;
    text-transform: uppercase;
    font-weight: 500;
    background-color: rgba(255, 102, 161, 0.3);
    padding: 2px;
    border-radius: 4px;
    text-align: center;
}

.ordinePianificato {
    color: #AD99FF;
    text-transform: uppercase;
    font-weight: 500;
    background-color: rgba(173, 153, 255, 0.3);
    padding: 2px;
    border-radius: 4px;
    text-align: center;
}

.CalendarDay {
    z-index: 999 !important;;
}

.DateRangePicker_picker {
    z-index: 999 !important;
}


@keyframes analyticsRosso {
    0%, 30% {
        background-color: rgba(219, 55, 55, 0.1);
    }
    30%, 100% {
        background-color: rgba(219, 55, 55, 0.8);
    }
}

@keyframes analyticsQtaErrata {
    0%, 30% {
        background-color: rgba(219, 55, 55, 0.1);
    }
    30%, 100% {
        background-color: rgba(219, 55, 55, 0.8);
    }
}










.month-picker {
    position: relative; }
.month-picker > .rmp-container {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 100;
    top: 1px;
    left: -10000px;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; }
@media screen and (max-width: 767px) {
    .month-picker > .rmp-container {
        position: fixed;
        top: 0;
        left: -10000px;
        width: 100%;
        height: 100%;
        transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; } }
.month-picker > .rmp-container.rmp-table {
    display: table; }
.month-picker > .rmp-container.show {
    left: 0;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
.month-picker > .rmp-container.show .rmp-overlay {
    left: 0; }
.month-picker > .rmp-container .rmp-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 9;
    top: 0;
    left: -10000px;
    opacity: 1;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: opacity;
    transform: translateZ(0);
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
@media screen and (max-width: 767px) {
    .month-picker > .rmp-container .rmp-overlay {
        background-color: rgba(0, 0, 0, 0.25); } }
.month-picker > .rmp-container .rmp-cell {
    display: table-cell;
    vertical-align: middle;
    box-sizing: border-box;
    width: 100%;
    height: 100%; }
@media screen and (max-width: 767px) {
    .month-picker > .rmp-container .rmp-cell {
        vertical-align: bottom; } }
.month-picker > .rmp-container .rmp-popup {
    position: absolute;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
    margin: 0 auto;
    z-index: 10;
    font-size: 1.2rem;
    opacity: 0;
    border-radius: 3px;
    padding: 0.4rem;
    box-sizing: content-box; }
@media screen and (max-width: 767px) {
    .month-picker > .rmp-container .rmp-popup {
        box-sizing: border-box;
        bottom: 0;
        width: 100%;
        max-width: 100%;
        height: 14.4rem;
        transform: translate3d(0, 14.4rem, 0); }
    .month-picker > .rmp-container .rmp-popup.range {
        height: 28rem;
        transform: translate3d(0, 28rem, 0); }
    .month-picker > .rmp-container .rmp-popup.range .rmp-pad {
        margin-top: 0.4rem; }
    .month-picker > .rmp-container .rmp-popup.range .rmp-pad:first-of-type {
        margin-top: 0; } }
@media screen and (min-width: 768px) {
    .month-picker > .rmp-container .rmp-popup {
        transform: translate3d(0, -64px, 0);
        top: 0;
        width: 20rem; }
    .month-picker > .rmp-container .rmp-popup.range {
        width: 40.6rem;
        padding: 0.6rem; } }
.month-picker > .rmp-container .rmp-popup.show {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
.month-picker > .rmp-container .rmp-popup:after {
    content: ' ';
    clear: both;
    display: table; }
.month-picker > .rmp-container .rmp-popup .rmp-pad {
    position: relative; }
@media screen and (min-width: 768px) {
    .month-picker > .rmp-container .rmp-popup .rmp-pad {
        box-sizing: border-box;
        float: left;
        width: 20rem; }
    .month-picker > .rmp-container .rmp-popup .rmp-pad:nth-of-type(2) {
        float: right; } }
.month-picker > .rmp-container .rmp-popup .rmp-pad > div label {
    display: block;
    font-size: 1.4rem;
    text-align: center;
    line-height: 3.4rem; }
.month-picker > .rmp-container .rmp-popup .rmp-pad > div label b {
    font-weight: normal;
    margin-right: 0.5em; }
.month-picker > .rmp-container .rmp-popup .rmp-pad > div i {
    font-style: normal;
    text-align: center;
    width: 3.4rem;
    height: 3.4rem;
    line-height: 3.4rem;
    position: absolute;
    top: 0; }
.month-picker > .rmp-container .rmp-popup .rmp-pad > div i.prev {
    left: 0; }
.month-picker > .rmp-container .rmp-popup .rmp-pad > div i.next {
    right: 0; }
.month-picker > .rmp-container .rmp-popup .rmp-pad ul, .month-picker > .rmp-container .rmp-popup .rmp-pad li {
    list-style-type: none;
    margin: 0;
    padding: 0; }
.month-picker > .rmp-container .rmp-popup .rmp-pad ul {
    display: block;
    width: 100%; }
.month-picker > .rmp-container .rmp-popup .rmp-pad ul:after {
    content: ' ';
    clear: both;
    display: table; }
.month-picker > .rmp-container .rmp-popup .rmp-pad li {
    display: block;
    float: left;
    text-align: center;
    font-size: 1.15rem;
    border-radius: 3px;
    line-height: 3.3rem;
    box-sizing: border-box;
    padding: 0.05rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    *white-space: nowrap;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
    transition: background-color 200ms ease-in-out, color 200ms ease-in-out; }
@media screen and (max-width: 767px) {
    .month-picker > .rmp-container .rmp-popup .rmp-pad li {
        width: 25%; } }
@media screen and (min-width: 768px) {
    .month-picker > .rmp-container .rmp-popup .rmp-pad li {
        width: 33.3333333333%; } }
.month-picker > .rmp-container .rmp-popup .rmp-pad li.multiple {
    background-clip: content-box; }
.month-picker > .rmp-container .rmp-popup .rmp-pad li.range {
    border-radius: 1px; }
.month-picker > .rmp-container .rmp-popup.light {
    color: #666;
    background-color: rgba(255, 255, 255, 0.96); }
@media screen and (max-width: 767px) {
    .month-picker > .rmp-container .rmp-popup.light {
        border-top: 1px solid #ccc;
        box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.08); }
    .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
        border-top: 1px solid rgba(204, 204, 204, 0.5); }
    .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad:first-of-type {
        border-top: 0; } }
@media screen and (min-width: 768px) {
    .month-picker > .rmp-container .rmp-popup.light {
        border: 1px solid #ccc;
        box-shadow: 0 1px 5px #ddd; }
    .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
        background-color: rgba(238, 238, 238, 0.9); } }
.month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn {
    cursor: pointer;
    moz-user-select: -moz-none;
    -moz-user-select: none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; }
@media screen and (min-width: 768px) {
    .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
        background-color: rgba(255, 227, 160, 0.59); } }
.month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn.select {
    background-color: #d3d3d3; }
.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active:hover {
    background-color: rgba(31, 42, 58, 0.73);
    color: white; }
.month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable:hover, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable:hover {
    background-color: transparent;
    color: #bbb;
    cursor: default; }
.month-picker > .rmp-container .rmp-popup.dark {
    color: #fff;
    background-color: rgba(50, 50, 50, 0.96); }
@media screen and (max-width: 767px) {
    .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
        border-top: 1px solid rgba(113, 113, 113, 0.41); }
    .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad:first-of-type {
        border-top: 0; } }
@media screen and (min-width: 768px) {
    .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
        background-color: rgba(70, 70, 70, 0.9); } }
.month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn {
    cursor: pointer;
    moz-user-select: -moz-none;
    -moz-user-select: none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; }
@media screen and (min-width: 768px) {
    .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn:hover {
        background-color: rgba(255, 210, 96, 0.33); } }
.month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn.select {
    background-color: #262828; }
.month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active:hover {
    background-color: rgba(189, 211, 242, 0.7);
    color: #303030; }
.month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable:hover, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.disable, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.disable:hover {
    background-color: transparent;
    color: #717171;
    cursor: default; }


.viewer-menu-item-label {
    color: black !important;
}


.drop-container {
    border: 1px dashed #fff;
    border-radius: 6px;
    background-color: rgba(33, 37, 41, 0.6);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
}

.dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.month-picker {
    text-transform: capitalize;
}

:focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}
